import { Label } from "@radix-ui/react-label";
import axios from "axios";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

import { Button } from "../../Components/Global/UI/Button";
import { Input } from "../../Components/Global/UI/Input";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../Components/Global/UI/Select";
import JobCard from "../../Components/Jobs/JobCard";
import StaffCard from "../../Components/Jobs/StaffCard";
import { BASE_URL } from "../Settings/DASHBOARD_SETTINGS";

const trustData = [
  {
    name: "Northern",
    lowMileagePrice: 396.55,
    lowMileage: 150,
    highMileagePrice: 301.54,
    highMileage: 225,
    extraPricePerMile: 0.5,
    lot: 4,
  },
  {
    name: "Western",
    lowMileagePrice: 398.21,
    lowMileage: 150,
    highMileagePrice: 301.9,
    highMileage: 225,
    extraPricePerMile: 0.5,
    lot: 10,
  },
  {
    name: "Belfast",
    lowMileagePrice: 680,
    lowMileage: 150,
    highMileagePrice: 724.96,
    highMileage: 225,
    extraPricePerMile: 0.5,
    lot: 2,
  },
  {
    name: "South Eastern",
    lowMileagePrice: 680,
    lowMileage: 150,
    highMileagePrice: 725.04,
    highMileage: 225,
    extraPricePerMile: 0.5,
    lot: 6,
  },
];

const calculatePrice = (
  trust,
  mileage,
  jobStartDate,
  logonTime,
  logoffTime,
  totalHoursFromForm
) => {
  const selectedTrust = trustData.find((trustItem) => trustItem.name === trust);
  if (!selectedTrust) return null;

  let duration;
  if (totalHoursFromForm !== "") {
    duration = totalHoursFromForm;
  } else {
    const [logonHour, logonMinute] = logonTime.split(":").map(Number);
    const [logoffHour, logoffMinute] = logoffTime.split(":").map(Number);

    let totalMinutes =
      (logoffHour - logonHour) * 60 + (logoffMinute - logonMinute);

    const jobStartHour = new Date(
      `${jobStartDate}T${logonTime}Z`
    ).getUTCHours();
    const jobEndHour = new Date(`${jobStartDate}T${logoffTime}Z`).getUTCHours();

    if (jobEndHour < jobStartHour) {
      totalMinutes += 24 * 60;
    }

    duration = totalMinutes / 60;
  }

  let basePrice = 0;
  if (mileage <= selectedTrust.lowMileage) {
    basePrice = selectedTrust.lowMileagePrice;
  } else if (mileage <= selectedTrust.highMileage) {
    basePrice = selectedTrust.highMileagePrice;
  }

  const shiftFraction = duration / 8;
  const price = basePrice * shiftFraction;

  return price.toFixed(2);
};

const AddShiftJob = () => {
  const [formData, setFormData] = useState({
    jobDetails: {
      trust: "",
      costCenter: "",
      jobStartDate: "",
      price: "",
      callSign: "",
      lot: "",
      vehicle: "",
    },
    staffDetails: {
      staffList: [
        { id: 1, name: "", jobType: "ACA" },
        { id: 2, name: "", jobType: "ACA" },
      ],
    },
    shiftDetails: {
      logonTime: "",
      logoffTime: "",
    },
    jobs: {
      totalMiles: "",
      totalJobs: "",
      jobList: [
        {
          id: 1,
          startingAddress: "",
          endingAddress: "",
          miles: "",
          referenceNumber: "",
        },
      ],
    },
    breakDetails: {
      break1: {
        visible: false,
        time: "",
      },
      break2: {
        visible: false,
        time: "",
      },
    },
  });

  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [drivers, setDrivers] = useState([]);

  const handleAddShiftJob = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/nias/job/create-nias-job`,
        {
          formData,
        }
      );
      if (response.data.success) {
        setLoading(false);
        toast.success(`Sucessfully Created Job`);
        history.push("/");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error("Item not found:", error.response.data.message);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("No response received:", error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  useEffect(() => {
    const { trust, jobStartDate } = formData.jobDetails;
    const { logonTime, logoffTime } = formData.shiftDetails;
    const totalMiles = parseFloat(formData.jobs.totalMiles);
    const totalHours = formData.shiftDetails.totalHours;

    if (
      !trust ||
      !jobStartDate ||
      !logonTime ||
      !logoffTime ||
      isNaN(totalMiles)
    )
      return;

    const price = calculatePrice(
      trust,
      totalMiles,
      jobStartDate,
      logonTime,
      logoffTime,
      totalHours
    );
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: { ...prevState.jobDetails, price },
    }));
  }, [
    formData.jobDetails.trust,
    formData.jobs.totalMiles,
    formData.jobDetails.jobStartDate,
    formData.shiftDetails.logonTime,
    formData.shiftDetails.logoffTime,
    formData.shiftDetails.totalHours,
  ]);

  React.useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);
        if (response.data.success) {
          setDrivers(response.data.drivers);
        }
      } catch (error) {
        console.error("Error fetching drivers:", error.message);
        toast.error("Failed to fetch drivers");
      }
    };
    fetchDrivers();
  }, []);

  const handleVehicleSelect = (selectedVehicle) => {
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: {
        ...prevState.jobDetails,
        vehicle: `${selectedVehicle.username}: ${selectedVehicle.registrationNumber}`,
      },
    }));
  };

  useEffect(() => {
    const totalJobs = formData.jobs.jobList.length;
    const totalMilage = formData.jobs.jobList.reduce(
      (acc, job) => acc + parseFloat(job.miles || 0),
      0
    );
    setFormData((prevState) => ({
      ...prevState,
      jobs: { ...prevState.jobs, totalJobs, totalMiles: totalMilage },
    }));
  }, [formData.jobs.jobList]);

  useEffect(() => {
    const { logonTime, logoffTime } = formData.shiftDetails;

    if (!logonTime || !logoffTime) return;

    const [logonHour, logonMinute] = logonTime.split(":").map(Number);
    const [logoffHour, logoffMinute] = logoffTime.split(":").map(Number);
    let totalMinutes =
      (logoffHour - logonHour) * 60 + (logoffMinute - logonMinute);

    if (logoffHour < logonHour) {
      totalMinutes += 24 * 60;
    }

    let break1Visible = false;
    let break2Visible = false;

    if (totalMinutes > 6 * 60 && totalMinutes <= 10.5 * 60) {
      break1Visible = true;
      totalMinutes -= 30;
    } else if (totalMinutes > 10.5 * 60) {
      break1Visible = true;
      break2Visible = true;
      totalMinutes -= 60;
    }

    const totalHours = totalMinutes / 60;

    setFormData((prevState) => ({
      ...prevState,
      shiftDetails: {
        ...prevState.shiftDetails,
        totalHours: totalHours,
      },
      breakDetails: {
        break1: { visible: break1Visible },
        break2: { visible: break2Visible },
      },
    }));
  }, [formData.shiftDetails.logonTime, formData.shiftDetails.logoffTime]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const updatePath = name.split(".");
    let updatedValue = value;

    if (type === "number") {
      updatedValue = parseFloat(value);
      if (isNaN(updatedValue)) updatedValue = 0;
    }

    setFormData((prevState) => {
      const newState = { ...prevState };
      let current = newState;
      updatePath.forEach((key, index) => {
        if (index === updatePath.length - 1) {
          current[key] = updatedValue;
        } else {
          current = current[key];
        }
      });
      return newState;
    });
  };

  const handleTrustSelect = (selectedTrust) => {
    const trust = trustData.find((trust) => trust.name === selectedTrust);
    if (trust) {
      setFormData((prevState) => ({
        ...prevState,
        jobDetails: {
          ...prevState.jobDetails,
          trust: selectedTrust,
          price: trust.lowMileagePrice.toString(),
          lot: trust.lot.toString(),
        },
      }));
    }
  };

  const handleCostCenterSelect = (selectedCostCenter) => {
    setFormData((prevState) => ({
      ...prevState,
      jobDetails: {
        ...prevState.jobDetails,
        costCenter: selectedCostCenter,
      },
    }));
  };

  const addStaffMember = () => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: [
          ...prevState.staffDetails.staffList,
          {
            id: prevState.staffDetails.staffList.length + 1,
            name: "",
            jobType: "ACA",
          },
        ],
      },
    }));
  };

  const handleStaffNameChange = (staffId, newName) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.map((staff) =>
          staff.id === staffId ? { ...staff, name: newName } : staff
        ),
      },
    }));
  };

  const handleJobDataChange = (jobId, field, value) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: prevState.jobs.jobList.map((job) =>
          job.id === jobId ? { ...job, [field]: value } : job
        ),
      },
    }));
  };

  const addJob = () => {
    const newJobId = formData.jobs.jobList.length + 1;
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: [
          ...prevState.jobs.jobList,
          { id: newJobId, startingAddress: "", endingAddress: "", miles: "" },
        ],
      },
    }));
  };

  const removeJob = (jobId) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: prevState.jobs.jobList.filter((job) => job.id !== jobId),
      },
    }));
  };

  const handleJobTypeChange = (staffId, jobType) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.map((staff) =>
          staff.id === staffId ? { ...staff, jobType } : staff
        ),
      },
    }));
  };

  const removeStaffMember = (staffId) => {
    setFormData((prevState) => ({
      ...prevState,
      staffDetails: {
        ...prevState.staffDetails,
        staffList: prevState.staffDetails.staffList.filter(
          (staff) => staff.id !== staffId
        ),
      },
    }));
  };

  const handleAddReturnJob = (returnJob) => {
    setFormData((prevState) => ({
      ...prevState,
      jobs: {
        ...prevState.jobs,
        jobList: [...prevState.jobs.jobList, returnJob],
      },
    }));
  };

  const callSignOptions = Array.from({ length: 10 }, (_, index) => {
    const callSignNumber = index + 1;
    return {
      value: `IJAC${callSignNumber.toString().padStart(2, "0")}`,
      label: `IJAC${callSignNumber.toString().padStart(2, "0")}`,
    };
  });

  return (
    <div className="px-10 my-8">
      <div className="mb-4">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-semibold leading-none mb-2">Job Details</p>
          <Button onClick={() => handleAddShiftJob()}>Create Job</Button>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 mb-4">
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="price">Starting Price</Label>
            <Input
              type="text"
              id="price"
              name="price"
              value={formData.jobDetails.price}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="logonTime">Trust</Label>
            <Select
              value={formData.jobDetails.trust}
              onValueChange={handleTrustSelect}
            >
              <SelectTrigger>
                {formData.jobDetails.trust
                  ? formData.jobDetails.trust
                  : "Select Trust"}
              </SelectTrigger>
              <SelectContent>
                {trustData.map((trust) => (
                  <SelectItem key={trust.name} value={trust.name}>
                    {trust.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="logonTime">Cost Center</Label>
            <Select
              value={formData.jobDetails.costCenter}
              onValueChange={handleCostCenterSelect}
            >
              <SelectTrigger>
                {formData.jobDetails.costCenter
                  ? formData.jobDetails.costCenter
                  : "Select Cost Center"}
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="FCAE01">EAC</SelectItem>
                <SelectItem value="FCPE01">NEAC</SelectItem>
                <SelectItem value="Trust">Trust</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="jobStartDate">Job Start Date</Label>
            <Input
              type="date"
              id="jobStartDate"
              name="jobDetails.jobStartDate"
              value={formData.jobDetails.jobStartDate}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="vehicle">Vehicle</Label>
            <Select
              value={formData.jobDetails.vehicle}
              onValueChange={handleVehicleSelect}
            >
              <SelectTrigger>
                {formData.jobDetails.vehicle
                  ? formData.jobDetails.vehicle
                  : "Select Vehicle"}
              </SelectTrigger>
              <SelectContent>
                {drivers.map((driver) => (
                  <SelectItem key={driver.username} value={driver}>
                    {driver.username}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="callSign">Call Sign</Label>
            <Input
              type="text"
              id="callSign"
              name="jobDetails.callSign"
              value={formData.jobDetails.callSign}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="lot">Lot Number</Label>
            <Input
              value={formData.jobDetails.lot}
              onChange={handleInputChange}
              type="text"
              id="lot"
              name="jobDetails.lot"
              placeholder="1"
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-row items-center mb-4 justify-between">
            <p className="text-lg font-semibold leading-none">Staff Details</p>
            <Button onClick={addStaffMember}>Add Staff</Button>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {formData.staffDetails.staffList.map((staff) => (
              <div key={staff.id}>
                <StaffCard
                  staff={staff}
                  onStaffNameChange={handleStaffNameChange}
                  onRemoveStaff={() => removeStaffMember(staff.id)}
                  onJobTypeChange={(jobType) =>
                    handleJobTypeChange(staff.id, jobType)
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <p className="text-lg font-semibold leading-none mb-2">
            Shift Details
          </p>
          <div className="grid w-full grid-cols-2 gap-4">
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="logonTime">Log on time</Label>
              <Input
                value={formData.shiftDetails.logonTime}
                onChange={handleInputChange}
                type="time"
                id="logonTime"
                name="shiftDetails.logonTime"
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="logoffTime">Log off time</Label>
              <Input
                value={formData.shiftDetails.logoffTime}
                onChange={handleInputChange}
                type="time"
                id="logoffTime"
                name="shiftDetails.logoffTime"
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="flex flex-row items-center mb-4 justify-between">
            <p className="text-lg font-semibold leading-none">Job Details</p>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {formData.jobs.jobList.map((job) => (
              <div key={job.id}>
                <JobCard
                  job={job}
                  onJobDataChange={handleJobDataChange}
                  onRemoveJob={() => removeJob(job.id)}
                  onAddReturnJob={handleAddReturnJob}
                />
              </div>
            ))}
            <Button onClick={addJob}>Add Job</Button>
          </div>
        </div>
        <div className="grid w-full grid-cols-2 gap-4 my-4">
          <div className="flex flex-col gap-1.5">
            <Label htmlFor="vehicle">Total Miles</Label>
            <Input
              value={formData.jobs.totalMiles}
              onChange={handleInputChange}
              type="number"
              id="totalMiles"
              name="totalMiles"
              placeholder="1"
            />
          </div>

          <div className="flex flex-col gap-1.5">
            <Label htmlFor="vehicle">Total Jobs</Label>
            <Input
              value={formData.jobs.totalJobs}
              onChange={handleInputChange}
              type="number"
              id="totalJobs"
              name="totalJobs"
              placeholder="1"
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="text-lg font-semibold leading-none mb-2">Break Details</p>
        <p className="text-sm leading-none mb-2">{`These are 30 min breaks - Break 1 visible from 6-8 hrs - Break 2 visible from >8 hrs `}</p>
        <div className="grid w-full grid-cols-2 gap-4">
          {formData.breakDetails.break1.visible && (
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="break1Time">Break 1 Time</Label>
              <Input
                type="time"
                id="break1Time"
                name="breakDetails.break1.time" // Note the use of dot notation for nested property
                value={formData.breakDetails.break1.time}
                onChange={handleInputChange}
              />
            </div>
          )}
          {formData.breakDetails.break2.visible && (
            <div className="flex flex-col gap-1.5">
              <Label htmlFor="break2Time">Break 2 Time</Label>
              <Input
                type="time"
                id="break2Time"
                name="breakDetails.break2.time" // Note the use of dot notation for nested property
                value={formData.breakDetails.break2.time}
                onChange={handleInputChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddShiftJob;
