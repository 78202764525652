import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Logo from '../../Assets/Images/JaceLOGO2.png';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../Screens/Settings/DASHBOARD_SETTINGS';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../Components/Global/UI/table';

const DriverJobs = ({ selectedDriver }) => {
  const [assignedJobs, setAssignedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const driver = selectedDriver?.driver;

  useEffect(() => {
    if (!driver?.driverId) {
      console.error('Driver ID is not provided');
      setAssignedJobs([]);
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/drivers/jobs/${driver.driverId}`);
        console.log('API Response:', response.data);
        setAssignedJobs(response.data.assignedJobs || []); // Handle missing or undefined data
        setLoading(false);
      } catch (error) {
        console.error('Error fetching driver jobs:', error.message);
        setAssignedJobs([]); // Default to empty array
        setLoading(false);
      }
    };

    fetchData();
  }, [driver?.driverId]);

  const handleUnassignJob = async (jobID) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/drivers/unassign-job/${driver.driverId}/${jobID}`);
      if (response.data.success) {
        toast.success('Job unassigned successfully');
        setAssignedJobs((prevJobs) => prevJobs.filter((job) => job._id !== jobID)); // Update state
      } else {
        toast.error(`Job unassignment failed: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error unassigning job:', error.message);
      toast.error('Error unassigning job');
    }
  };

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>DOB</TableHead>
            <TableHead>Reference</TableHead>
            <TableHead>Collection Time</TableHead>
            <TableHead>From</TableHead>
            <TableHead>To</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <TableRow key={index}>
                <TableCell colSpan="7" className="text-center">
                  <div className="h-6 bg-gray-300 rounded-md animate-pulse w-1/2 mx-auto"></div>
                </TableCell>
              </TableRow>
            ))
          ) : assignedJobs.length > 0 ? (
            assignedJobs.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <div className="h-[30px] w-[30px] rounded-full">
                      <img src={Logo} className="h-full w-full rounded-full" alt="Driver Logo" />
                    </div>
                    <span className="font-semibold text-gray-600">{item.patientDetails.name}</span>
                  </div>
                </TableCell>
                <TableCell>{new Date(item.patientDetails.dob).toLocaleDateString('en-GB')}</TableCell>
                <TableCell>{item.referenceCode.toUpperCase()}</TableCell>
                <TableCell>
                  {new Date(item.journeyDetails.journeys[0].dateTimePickup).toLocaleString()}
                </TableCell>
                <TableCell>
                  {item.journeyDetails.journeys[0].pickupLocation === 'Other'
                    ? item.journeyDetails.journeys[0].otherPickupLocation
                    : item.journeyDetails.journeys[0].pickupLocation}
                </TableCell>
                <TableCell>
                  {item.journeyDetails.journeys[0].dropOffLocation === 'Other'
                    ? item.journeyDetails.journeys[0].otherDropOffLocation
                    : item.journeyDetails.journeys[0].dropOffLocation}
                </TableCell>
                <TableCell>
                  <button
                    onClick={() => handleUnassignJob(item._id)}
                    className="px-4 py-2 bg-red-400 text-white rounded font-semibold"
                  >
                    Unassign Job
                  </button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="7" className="text-center py-4">
                No jobs assigned to this driver.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default DriverJobs;