import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";

import { Button } from "../Components/Global/UI/Button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../Components/Global/UI/Card";
import { Input } from "../Components/Global/UI/Input";
import { Label } from "../Components/Global/UI/Label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../Components/Global/UI/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../Components/Global/UI/table";
import { BASE_URL } from "./Settings/DASHBOARD_SETTINGS";

const Scheduler = () => {
  const [trust, setTrust] = useState("");
  const [costCenter, setCostCenter] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState("");
  const [lot, setLot] = useState("");
  const [startingPrice, setStartingPrice] = useState("");
  const [schedule, setSchedule] = useState({});
  const [currentDay, setCurrentDay] = useState("");
  const [currentShifts, setCurrentShifts] = useState([]);
  const [shiftAddress, setShiftAddress] = useState("");
  const [callSign, setCallSign] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [previewSchedule, setPreviewSchedule] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);

  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const trustData = {
    Northern: { lot: "4", startingPrice: 396.55 },
    Western: { lot: "10", startingPrice: 398.21 },
    Belfast: { lot: "2", startingPrice: 680 },
    "South Eastern": { lot: "6", startingPrice: 680 },
  };

  const costCenters = [
    { name: "EAC", value: "FCAE01" },
    { name: "NEAC", value: "FCPE01" },
    { name: "Trust", value: "Trust" },
  ];

  const updateSchedule = (updatedShifts) => {
    setSchedule((prev) => ({
      ...prev,
      [currentDay]: updatedShifts,
    }));
    setCurrentShifts(updatedShifts);
  };

  const handleTrustChange = (value) => {
    setTrust(value);
    if (trustData[value]) {
      setLot(trustData[value].lot);
      setStartingPrice(trustData[value].startingPrice);
    } else {
      setLot("");
      setStartingPrice("");
    }
  };

  const handleAddShift = () => {
    if (!startTime || !endTime || !shiftAddress || !callSign) {
      toast.error("Please specify start time, end time, address, and call sign.");
      return;
    }

    const newShift = { startTime, endTime, address: shiftAddress, callSign };
    const updatedShifts = [...currentShifts, newShift];
    updateSchedule(updatedShifts);

    setStartTime("");
    setEndTime("");
    setShiftAddress("");
    setCallSign("");
    toast.success(`Shift added to ${currentDay}!`);
  };

  const handleDaySelection = (day) => {
    setCurrentDay(day);
    setCurrentShifts(schedule[day] || []);
    setEditingIndex(null);
  };

  const handleUpdateShift = (index, field, value) => {
    const updatedShifts = [...currentShifts];
    updatedShifts[index][field] = value;
    updateSchedule(updatedShifts);
  };

  const handleSaveShiftEdit = () => {
    setEditingIndex(null);
    toast.success("Shift updated successfully!");
  };

  const handleRemoveShift = (index) => {
    const updatedShifts = currentShifts.filter((_, i) => i !== index);
    updateSchedule(updatedShifts);
    toast.success("Shift removed successfully!");
  };

  const handlePreview = () => {
    if (
      !trust ||
      !duration ||
      !startDate ||
      Object.keys(schedule).length === 0
    ) {
      toast.error(
        "Please complete all fields and add shifts for at least one day."
      );
      return;
    }

    const preview = [];
    const startMoment = moment(startDate);
    const totalDays =
      duration === "1-week" ? 7 : duration === "1-month" ? 30 : 90;

    const costCenterName =
      costCenters.find((center) => center.value === costCenter)?.name ||
      costCenter;

    for (let i = 0; i < totalDays; i++) {
      const currentDate = startMoment.clone().add(i, "days");
      const dayName = currentDate.format("dddd");

      if (schedule[dayName]) {
        schedule[dayName].forEach((shift) => {
          preview.push({
            trust,
            lot,
            startingPrice,
            costCenterName,
            date: currentDate.format("DD/MM/YYYY"),
            day: dayName,
            startTime: shift.startTime,
            endTime: shift.endTime,
            address: shift.address,
            callSign: shift.callSign,
          });
        });
      }
    }

    setPreviewSchedule(preview);
    toast.success("Preview generated!");
  };

  const handleSubmit = async () => {
    if (previewSchedule.length === 0) {
      toast.error("Please generate the preview before submitting.");
      return;
    }

    try {
      const scheduleData = Object.entries(schedule).map(
        ([day, shifts]) => ({
          day,
          shifts: shifts.map((shift) => ({
            startTime: shift.startTime,
            endTime: shift.endTime,
            address: shift.address,
            callSign: shift.callSign,
          })),
        })
      );

      const response = await axios.post(
        `${BASE_URL}/api/nias/job/schedule-shifts`,
        {
          trust,
          costCenter,
          startDate,
          duration,
          lot,
          price: startingPrice,
          scheduleData,
        }
      );

      if (response.data.success) {
        toast.success("Schedule created successfully!");
      } else {
        toast.error(response.data.msg || "Failed to create schedule.");
      }
    } catch (error) {
      console.error("Error submitting schedule:", error);
      toast.error("An error occurred while creating the schedule.");
    }
  };

  const handleCopyPreviousDay = () => {
    if (!currentDay) {
      toast.error("Please select a day.");
      return;
    }
  
    const currentIndex = daysOfWeek.indexOf(currentDay);
    if (currentIndex === 0) {
      toast.error("No previous day available to copy.");
      return;
    }
  
    const previousDay = daysOfWeek[currentIndex - 1];
    const previousDayData = schedule[previousDay];
  
    if (!previousDayData || previousDayData.length === 0) {
      toast.error(`No shifts found for ${previousDay} to copy.`);
      return;
    }
  
    // Copy the previous day's shifts
    const copiedShifts = previousDayData.map((shift) => ({
      ...shift, // Copy all shift details (startTime, endTime, address, callSign)
    }));
  
    // Update the schedule and local state
    setCurrentShifts(copiedShifts);
  
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [currentDay]: copiedShifts,
    }));
  
    toast.success(
      `Shifts copied successfully from ${previousDay} to ${currentDay}!`
    );
  };


  return (
    <div className="container mx-auto p-6">
      <Card>
        <CardHeader>
          <CardTitle className="text-lg font-bold">
            Create Shift Schedule
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div>
                <Label>Trust</Label>
                <Select onValueChange={handleTrustChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select trust" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(trustData).map((trust) => (
                      <SelectItem key={trust} value={trust}>
                        {trust}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label>Cost Center</Label>
                <Select onValueChange={(value) => setCostCenter(value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select cost center" />
                  </SelectTrigger>
                  <SelectContent>
                    {costCenters.map((center) => (
                      <SelectItem key={center.value} value={center.value}>
                        {center.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label>Duration</Label>
                <Select onValueChange={(value) => setDuration(value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select duration" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="1-week">1 Week</SelectItem>
                    <SelectItem value="1-month">1 Month</SelectItem>
                    <SelectItem value="1-quarter">1 Quarter</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label htmlFor="startDate">Start Date</Label>
                <Input
                  type="date"
                  id="startDate"
                  value={startDate}
                  min={moment().format("YYYY-MM-DD")}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <Label>Lot</Label>
                <Input type="text" value={lot} readOnly />
              </div>
              <div>
                <Label>Starting Price</Label>
                <Input type="text" value={startingPrice} readOnly />
              </div>
            </div>
            <div className="border p-4 rounded">
              <h3 className="font-bold text-lg mb-2">Add Shifts for a Day</h3>
              <div>
                <Label>Select Day</Label>
                <Select onValueChange={(value) => handleDaySelection(value)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select day" />
                  </SelectTrigger>
                  <SelectContent>
                    {daysOfWeek.map((day) => (
                      <SelectItem key={day} value={day}>
                        {day}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center gap-4 mt-4">
                <div>
                  <Label>Start Time</Label>
                  <Input
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </div>
                <div>
                  <Label>End Time</Label>
                  <Input
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </div>
                <div>
                  <Label>Address</Label>
                  <Input
                    type="text"
                    placeholder="Enter address for this shift"
                    value={shiftAddress}
                    onChange={(e) => setShiftAddress(e.target.value)}
                  />
                </div>
                <div>
                  <Label>Call Sign</Label>
                  <Input
                    type="text"
                    placeholder="Enter call sign for this shift"
                    value={callSign}
                    onChange={(e) => setCallSign(e.target.value)}
                  />
                </div>
                <Button onClick={handleAddShift}>Add Shift</Button>
              </div>
              {currentShifts.length > 0 && (
                <div className="mt-4">
                  <h4 className="font-bold">
                    Shifts for {currentDay || "Selected Day"}:
                  </h4>
                  <ul>
                    {currentShifts.map((shift, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center gap-4"
                      >
                        {editingIndex === index ? (
                          <div className="flex items-center gap-2">
                            <Input
                              type="time"
                              value={shift.startTime}
                              onChange={(e) =>
                                handleUpdateShift(
                                  index,
                                  "startTime",
                                  e.target.value
                                )
                              }
                            />
                            <Input
                              type="time"
                              value={shift.endTime}
                              onChange={(e) =>
                                handleUpdateShift(
                                  index,
                                  "endTime",
                                  e.target.value
                                )
                              }
                            />
                            <Input
                              type="text"
                              value={shift.address}
                              onChange={(e) =>
                                handleUpdateShift(
                                  index,
                                  "address",
                                  e.target.value
                                )
                              }
                            />
                            <Input
                              type="text"
                              value={shift.callSign}
                              onChange={(e) =>
                                handleUpdateShift(
                                  index,
                                  "callSign",
                                  e.target.value
                                )
                              }
                            />
                            <Button size="sm" onClick={handleSaveShiftEdit}>
                              Save
                            </Button>
                          </div>
                        ) : (
                          <span>
                            {shift.startTime} - {shift.endTime} | {shift.address} | {shift.callSign}
                          </span>
                        )}
                        <div className="flex gap-2">
                          {editingIndex !== index && (
                            <Button
                              size="sm"
                              variant="secondary"
                              onClick={() => setEditingIndex(index)}
                            >
                              Edit
                            </Button>
                          )}
                          <Button
                            size="sm"
                            variant="destructive"
                            onClick={() => handleRemoveShift(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="mt-4">
              <Button variant="secondary" onClick={handleCopyPreviousDay}>
                Copy Previous Day
              </Button>
            </div>
          </div>
          {previewSchedule.length > 0 && (
            <div className="mt-6">
              <h3 className="font-bold text-lg mb-2">Preview Schedule</h3>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Trust</TableHead>
                    <TableHead>Lot</TableHead>
                    <TableHead>Starting Price</TableHead>
                    <TableHead>Cost Center</TableHead>
                    <TableHead>Day</TableHead>
                    <TableHead>Date</TableHead>
                    <TableHead>Start Time</TableHead>
                    <TableHead>End Time</TableHead>
                    <TableHead>Start Address</TableHead>
                    <TableHead>Call Sign</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {previewSchedule.map((entry, index) => (
                    <TableRow key={index}>
                      <TableCell>{entry.trust}</TableCell>
                      <TableCell>{entry.lot}</TableCell>
                      <TableCell>{entry.startingPrice}</TableCell>
                      <TableCell>{entry.costCenterName}</TableCell>
                      <TableCell>{entry.day}</TableCell>
                      <TableCell>{entry.date}</TableCell>
                      <TableCell>{entry.startTime}</TableCell>
                      <TableCell>{entry.endTime}</TableCell>
                      <TableCell>{entry.address}</TableCell>
                      <TableCell>{entry.callSign}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
        <CardFooter>
          <div className="flex space-x-4">
            <Button onClick={handlePreview}>Generate Preview</Button>
            <Button variant="primary" onClick={handleSubmit}>
              Create Schedule
            </Button>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Scheduler;
