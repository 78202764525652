import React from 'react'

import Logo from "../../Assets/Images/JaceLOGO2.png";
import { Checkbox } from '../../Components/Global/UI/checkbox';
import { Table, TableBody, TableCaption, TableCell, TableFooter, TableHead, TableHeader, TableRow } from "../../Components/Global/UI/table"

const InvoicingShift = ({ loading, invoices, onSelectedItemsChange }) => {
  const [selectedItems, setSelectedItems] = React.useState([]);

  const toggleRowSelection = (item) => {
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some(
        (selectedItem) => selectedItem._id === item._id
      );

      if (isSelected) {
        const newSelected = prevSelected.filter(
          (selectedItem) => selectedItem._id !== item._id
        );
        onSelectedItemsChange(newSelected); // Call onChanged with newSelected
        return newSelected;
      } else {
        const newSelected = [...prevSelected, item];
        onSelectedItemsChange(newSelected); // Call onChanged with newSelected
        return newSelected;
      }
    });
  };

  const toggleSelectAll = () => {
    if (selectedItems.length === invoices.length) {
      // Deselect all
      setSelectedItems([]);
      onSelectedItemsChange([]);
    } else {
      // Select all
      setSelectedItems(invoices);
      onSelectedItemsChange(invoices);
    }
  };

  React.useEffect(() => {
    if (invoices) {
      const defaultSelected = invoices.reduce((selected, item) => {
        if (!item.invoiced) {
          return [...selected, item];
        }
        return selected;
      }, []);
      setSelectedItems(defaultSelected);
      onSelectedItemsChange(defaultSelected);
    }
  }, [invoices]);

  const calculateTotalPrice = () => {
    if (!invoices) return "£0.00";
    const totalPrice = selectedItems.reduce(
      (total, item) => total + parseFloat(item.jobDetails.price),
      0
    );
    return "£" + totalPrice.toLocaleString("en-GB", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const getCostCenterName = (costCenter) => {
    switch (costCenter) {
      case "FCAE01":
        return "EAC";
      case "FCPE01":
        return "NEAC";
      case "Trust":
        return "Trust";
      default:
        return "N/A";
    }
  };

  return (
    <div>
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div
              key={index}
              className="overflow-hidden border border-gray-200 rounded-md animate-pulse"
            >
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>
                  <Checkbox
                    checked={selectedItems.length === invoices.length && invoices.length > 0}
                    onCheckedChange={toggleSelectAll}
                    aria-label="Select All"
                  />
                </TableHead>
                <TableHead>Trust</TableHead>
                <TableHead>Cost Center</TableHead>
                <TableHead>Reference</TableHead>
                <TableHead>Log On</TableHead>
                <TableHead>Log Off</TableHead>
                <TableHead>Total Hours</TableHead>
                <TableHead>Total Miles</TableHead>
                <TableHead>Vehicle</TableHead>
                <TableHead>Start Date</TableHead>
                <TableHead>Price</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!loading
                ? invoices.map((item, index) => (
                    <TableRow
                      className="cursor-pointer"
                      onClick={() => toggleRowSelection(item)}
                      key={index}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedItems.some(
                            (selectedItem) => selectedItem._id === item._id
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <span>
                          {item.jobDetails ? item.jobDetails.trust : "N/A"}
                        </span>
                      </TableCell>
                      <TableCell>
                        {getCostCenterName(item.jobDetails.costCenter)}
                      </TableCell>
                      <TableCell>
                        {item.jobDetails.referenceCode.toUpperCase()}
                      </TableCell>
                      <TableCell>{item.shiftDetails.logonTime}</TableCell>
                      <TableCell>{item.shiftDetails.logoffTime}</TableCell>
                      <TableCell>
                        {item.shiftDetails.totalHours
                          ? item.shiftDetails.totalHours.toFixed(2)
                          : ""}
                      </TableCell>
                      <TableCell>{item.jobs.totalMiles.toFixed(0)}</TableCell>
                      <TableCell>{item.jobDetails.vehicle}</TableCell>
                      <TableCell>
                        {new Date(item.jobDetails.jobStartDate).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          }
                        )}
                      </TableCell>
                      <TableCell>
                        £
                        {parseFloat(item.jobDetails.price).toLocaleString(
                          "en-GB",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                : "Loading..."}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell className="font-medium" colSpan={9}>
                  Total
                </TableCell>
                <TableCell>{calculateTotalPrice()}</TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </>
      )}
    </div>
  );
};

export default InvoicingShift;