import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import { Button } from "../Global/UI/Button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../Global/UI/Select";

const ListView = ({ onBackToCalendar }) => {
  const [events, setEvents] = useState([]);
  const [dayOffset, setDayOffset] = useState(0); // Tracks the current offset in days
  const [sortMethod, setSortMethod] = useState("time"); // Default sorting by time
  const dayCount = 2; // Number of days to show at a time

  // Generate the days to display based on dayOffset
  const currentDays = Array.from({ length: dayCount }).map((_, index) =>
    moment()
      .startOf("day")
      .add(dayOffset + index, "days")
  );

  const fetchJobs = async () => {
    const startDate = currentDays[0].format("YYYY-MM-DD");
    const endDate = currentDays[dayCount - 1].format("YYYY-MM-DD");

    try {
      const response = await fetch(
        `${BASE_URL}/api/nias/job/get-calendar-events?startDate=${startDate}&endDate=${endDate}`
      );
      const data = await response.json();

      if (data.success) {
        setEvents(data.results);
      } else {
        console.error("Failed to fetch jobs:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [dayOffset]); // Fetch jobs whenever dayOffset changes

  const getCostCenterName = (costCenter) => {
    switch (costCenter) {
      case "FCAE01":
        return "EAC";
      case "FCPE01":
        return "NEAC";
      case "Trust":
        return "Trust";
      default:
        return "N/A";
    }
  };

  const getTrustShortCode = (trust) => {
    switch (trust) {
      case "Northern":
        return "NT";
      case "Western":
        return "WT";
      case "Belfast":
        return "BT";
      case "south eastern":
      case "south east":
        return "SET";
      default:
        return "N/A";
    }
  };

  const getTextColour = (trust) => {
    switch (trust.toLowerCase()) {
      case "belfast":
        return "#10B981";
      case "northern":
        return "#7dd3fc";
      case "western":
        return "#EF4444";
      case "south eastern":
      case "south east":
        return "#F59E0B";
      default:
        return "#6B7280";
    }
  };

  const sortEvents = (events) => {
    if (sortMethod === "time") {
      // Sort by logonTime
      return events.sort((a, b) => {
        const timeA = a.logonTime ? moment(a.logonTime, "HH:mm") : moment(0); // Default to earliest time if missing
        const timeB = b.logonTime ? moment(b.logonTime, "HH:mm") : moment(0);
        return timeA - timeB;
      });
    } else if (sortMethod === "costCenter") {
      // Group by Cost Center and Trust, then sort by time
      const groupedAndSorted = events.reduce((acc, event) => {
        const costCenter = getCostCenterName(event.costCenter);
        const trust = getTrustShortCode(event.trust);
  
        // Add grouping keys to maintain order during flattening
        acc.push({ ...event, _groupKey: `${costCenter}_${trust}` });
        return acc;
      }, []);
  
      // Sort by cost center, trust, and then logon time
      return groupedAndSorted.sort((a, b) => {
        const groupA = a._groupKey;
        const groupB = b._groupKey;
  
        if (groupA === groupB) {
          const timeA = a.logonTime ? moment(a.logonTime, "HH:mm") : moment(0);
          const timeB = b.logonTime ? moment(b.logonTime, "HH:mm") : moment(0);
          return timeA - timeB; // Sort by time within the group
        }
        return groupA.localeCompare(groupB); // Sort by group key
      });
    }
    return events;
  };

  return (
    <div className="custom-list-view">
      {/* Back to Calendar Button */}
      <Button variant="outline" className="mb-4" onClick={onBackToCalendar}>
        Back to Calendar
      </Button>

      <div className="flex justify-between items-center mb-4">
        {/* Back Button */}
        <Button
          variant="outline"
          className="flex items-center"
          onClick={() => setDayOffset((prev) => prev - dayCount)}
        >
          <ChevronLeftIcon className="w-5 h-5" />
          <span className="ml-2">Back</span>
        </Button>

        {/* Display Range */}
        <div className="font-bold">
          {currentDays[0].format("DD MMM")} -{" "}
          {currentDays[dayCount - 1].format("DD MMM")}
        </div>

        {/* Forward Button */}
        <Button
          variant="outline"
          className="flex items-center"
          onClick={() => setDayOffset((prev) => prev + dayCount)}
        >
          <span className="mr-2">Forward</span>
          <ChevronRightIcon className="w-5 h-5" />
        </Button>
      </div>

      {/* Sort Dropdown */}
      <div className="mb-4">
        <Select value={sortMethod} onValueChange={setSortMethod}>
          <SelectTrigger>
            <SelectValue placeholder="Sort By" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="time">Default</SelectItem>
            <SelectItem value="costCenter">Cost Center</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {/* Grid for Days */}
      <div className={`grid grid-cols-${dayCount} gap-2`}>
        {currentDays.map((day) => {
          // Filter and sort events for the specific day
          const dailyEvents = sortEvents(
            events.filter((event) =>
              moment(event.jobStartDate).isSame(day, "day")
            )
          );

          return (
            <div
              key={day.format("YYYY-MM-DD")}
              className="text-center p-4 bg-white shadow-lg rounded"
            >
              {/* Day and Date */}
              <div className="font-bold text-lg">{day.format("dddd")}</div>
              <div className="text-sm text-gray-500 mb-4">
                {day.format("DD MMM")}
              </div>

              {/* Total Jobs for the Day */}
              <div className="font-medium mb-2">
                Total Jobs: {dailyEvents.length}
              </div>

              {/* Events for the Day */}
              <div className="space-y-2 text-white">
                {dailyEvents.map((event) => (
                  <div
                    key={event.id}
                    className="p-2 rounded shadow-sm flex flex-col items-start gap-1"
                    style={{ backgroundColor: getTextColour(event.trust) }}
                  >
                    <div className="text-sm w-full grid grid-cols-5 justify-between">
                      {/* Cost Center, Trust, Call Sign, Time, Crew */}
                      <div className="flex flex-col text-left">
                        <p>Center</p>
                        <span className="py-1 rounded font-semibold">
                          {getCostCenterName(event.costCenter) || "N/A"}
                        </span>
                      </div>
                      <div className="flex flex-col text-left">
                        <p>Trust</p>
                        <span className="py-1 rounded font-semibold">
                          {getTrustShortCode(event.trust) || "N/A"}
                        </span>
                      </div>
                      <div className="flex flex-col text-left">
                        <p>Call Sign</p>
                        <span className="py-1 rounded font-semibold">
                          {event.callSign || "N/A"}
                        </span>
                      </div>
                      <div className="flex flex-col text-left">
                        <p>Time</p>
                        <div className="text-sm font-semibold">
                          {event.logonTime || "0:00"} -{" "}
                          {event.logoffTime || "0:00"}
                        </div>
                      </div>
                      <div className="flex flex-col text-left">
                        <p>Crew</p>
                        <div className="text-sm font-semibold whitespace-pre-line">
                          {event.drivers && event.drivers.length > 0
                            ? event.drivers
                                .map((staff) => staff.name.trim() || "N/A")
                                .join("\n")
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {dailyEvents.length === 0 && (
                  <div className="text-gray-500 text-sm">No events</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListView;
