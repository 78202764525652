import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Utility function to merge class names conditionally.
 * 
 * @param  {...any} inputs - The class names or conditions to merge.
 * @returns {string} - A merged string of class names.
 */
export function cn(...inputs) {
  return twMerge(clsx(inputs));
}