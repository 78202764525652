import axios from "axios";
import { format } from "date-fns";
import moment from "moment";
import Papa from "papaparse";
import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

import { Button, buttonVariants } from "../../Components/Global/UI/Button";
import { DatePickerWithRange } from "../../Components/Global/UI/DateRangePicker";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../../Components/Global/UI/Select";
import { useAuth } from "../../Context/AuthProvider";
import { BASE_URL } from "../Settings/DASHBOARD_SETTINGS";
import InvoicingJourney from "./InvoicingJourney";
import InvoicingRenal from "./InvoicingRenal";
import InvoicingShift from "./InvoicingShift";

const InvoicingPanel = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [activeTab, setActiveTab] = useState("Journey");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedShiftItems, setShiftItems] = useState([]);
  const [tokenExists, setTokenExists] = React.useState(true);
  const { authData } = useAuth();

  React.useEffect(() => {
    setLoading(true);
    checkTokenExists();
  }, []);

  const checkTokenExists = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/xero/check-token-exists/${authData.userId}`
      );
      setTokenExists(response.data.success);
      setLoading(false);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const connectXero = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/xero/connect/${authData.userId}`
      );
      redirectToXeroAuthorization(response.data.data);
      setLoading(false);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const redirectToXeroAuthorization = (authorizationUrl) => {
    window.location.href = authorizationUrl;
  };

  const handleErrorResponse = (error) => {
    setLoading(false);
    if (error.response) {
      toast.error(
        error.response.data.message || "An unknown error has occurred"
      );
    } else {
      toast.error("An unknown error has occurred");
    }
  };

  if (!tokenExists) {
    return (
      <div className="flex flex-1 justify-center pt-40">
        <div className="flex flex-col max-w-sm">
          <p className="font-semibold text-2xl text-center">
            Connect your Xero Account
          </p>
          <p className="font-light text-sm max-w-sm text-center pt-1 pb-4">
            To continue using the invoicing feature please connect your Xero
            account to the JaceMedical Dashboard
          </p>
          <Button
            onClick={() => connectXero()}
            variant="default"
            size="default"
            className={buttonVariants({ variant: "default", size: "default" })}
          >
            Connect Xero Account
          </Button>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ThreeDots
          height="80"
          width="80"
          color="#000000"
          ariaLabel="tail-spin-loading"
          radius="1"
          visible={true}
        />
      </div>
    );
  }

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleSelectedItemsChange = (items) => {
    console.log("Items Changed:", items);
    setSelectedItems(items);
  };

  const handleSelectedShiftItemsChange = (items) => {
    console.log("Shift Items Changed:", items);
    setShiftItems(items);
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    } else {
      return "Invalid date";
    }
  };

  const getInvoices = () => {
    if (activeTab === "Journey") {
      getJourneyInvoices();
    } else if (activeTab === "Shift") {
      getShiftInvoices();
    } else if (activeTab === "NIASRenal") {
      getNIASRenalInvoices();
    } else if (activeTab === "Repatriation") {
      getNIASRenalInvoices();
    }
  };

  const getShiftInvoices = async () => {
    const formattedFromDate = format(selectedDate.from, "yyyy-MM-dd");
    const formattedToDate = format(selectedDate.to, "yyyy-MM-dd");

    try {
      setLoading(true);
      toast.info("🔥 Invoices are being Generated.");
      const response = await axios.get(
        `${BASE_URL}/api/admin/generate-invoices-shifts?from=${formattedFromDate}&to=${formattedToDate}`
      );
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully Generated.");
        setInvoices(response.data.results);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error("Validation errors:", error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("No response received:", error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const getJourneyInvoices = async () => {
    const formattedFromDate = format(selectedDate.from, "yyyy-MM-dd");
    const formattedToDate = format(selectedDate.to, "yyyy-MM-dd");
    try {
      setLoading(true);
      toast.info("🔥 Invoices are being Generated.");
      const response = await axios.get(
        `${BASE_URL}/api/admin/generate-invoices?from=${formattedFromDate}&to=${formattedToDate}`
      );
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully Generated.");
        setInvoices(response.data.results);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error("Validation errors:", error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("No response received:", error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  const getNIASRenalInvoices = async () => {
    const formattedFromDate = format(selectedDate.from, "yyyy-MM-dd");
    const formattedToDate = format(selectedDate.to, "yyyy-MM-dd");
    try {
      setLoading(true);
      toast.info("🔥 Invoices are being Generated.");
      const response = await axios.get(
        `${BASE_URL}/api/admin/generate-invoices-nias-renal?from=${formattedFromDate}&to=${formattedToDate}`
      );
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully Generated.");
        setInvoices(response.data.results);
        console.log("Items:", response.data.results);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setLoading(false);
          toast.error(error.response.data.message);
          console.error("Validation errors:", error.response.data.errors);
        }
      } else if (error.request) {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("No response received:", error.request);
      } else {
        setLoading(false);
        toast.error("An unknown error has occured");
        console.error("Error setting up the request:", error.message);
      }
    }
  };

  let headers = [];

  const getInitials = (name) => {
    if (!name || typeof name !== "string") return "N/A";
    return name
      .trim() // Remove leading/trailing whitespace
      .split(" ") // Split the name into words
      .filter((word) => word) // Remove empty strings
      .map((word) => word[0].toUpperCase()) // Get the first letter of each word
      .join(""); // Join the initials
  };

  const sendNIASInvoicesToXero = async () => {
    const invoices = {
      invoices: selectedShiftItems
        .map((invoice) => {
          console.log("Invoice: ", invoice);
          const visibleBreaksCount = Object.values(invoice.breakDetails).filter(
            (breakItem) => breakItem.visible
          ).length;
  
          let title = "NIAS Shift";
  
          if (invoice.jobDetails.costCenter === "Trust") {
            title = "Shift";
          }
  
          const jobDetails = invoice.jobs?.jobList || [];
          const jobDescriptions = jobDetails
            .map((job, index) => {
              return [
                `Trip #${index + 1}`,
                `Patient Name: ${getInitials(job.patientName) || "N/A"}`,
                `Patient DOB: ${moment(job.patientDOB).format('DD/MM/YYYY') || "N/A"}`,
                `Starting Address: ${job.startingAddress || "N/A"}`,
                `Ending Address: ${job.endingAddress || "N/A"}`,
                `Reference Number: ${job.referenceNumber || "N/A"}`,
              ].join("\n");
            })
            .join("\n\n");
  
          const description = [
            title,
            `TRUST: ${invoice.jobDetails.trust}`,
            `Cost Code: ${invoice.jobDetails.costCenter}`,
            `Call Sign: ${invoice.jobDetails.callSign}`,
            `DATE: ${formatDateTime(invoice.jobDetails.jobStartDate)}`,
            `Log on time: ${invoice.shiftDetails.logonTime}`,
            `Log off time: ${invoice.shiftDetails.logoffTime}`,
            `Total Hour: ${invoice.shiftDetails.totalHours}`,
            `Total Miles: ${invoice.jobs.totalMiles.toFixed(2)}`,
            `Staff: ${invoice.staffDetails.staffList.map(staff => staff.name).join(", ")}`,
            `Total Jobs: ${invoice.jobs.totalJobs}`,
            `Breaks: ${visibleBreaksCount}x 30 Min Breaks`,
            `\nJob Details:\n${jobDescriptions}`,
          ].join("\n");
  
          const unitAmount = parseFloat(invoice.jobDetails.price);
          const lineAmount = parseFloat(invoice.jobDetails.price);
  
          if (isNaN(unitAmount) || isNaN(lineAmount)) {
            console.error("Invalid amount detected in invoice:", invoice);
            return null;
          }
  
          let contactID = "950e422a-4163-4ee9-b32d-b97649175a53";
  
          if (invoice.jobDetails.costCenter === "Trust") {
            console.log(`Trust Found: ${invoice.jobDetails.trust}`);
            contactID = getTrustContactID(invoice.jobDetails.trust);
          }
  
          console.log(`Contact ID: ${contactID}`);

          console.log(description)
  
          return {
            type: "ACCREC",
            invoiceNumber: `${invoice.jobDetails.referenceCode}`,
            contact: {
              contactID: contactID,
            },
            lineItems: [
              {
                description: description,
                quantity: 1.0,
                unitAmount: unitAmount,
                accountCode: "201",
                taxType: "NONE",
                lineAmount: lineAmount,
              },
            ],
            date: moment(invoice.jobDetails.jobStartDate).format("YYYY-MM-DD"),
            dueDate: moment(invoice.jobDetails.jobStartDate)
              .add(30, "days")
              .format("YYYY-MM-DD"),
            reference: `${invoice.jobDetails.referenceCode}`,
            status: "DRAFT",
          };
        })
        .filter((invoice) => invoice !== null), // Filter out any invalid invoices
    };
  
    try {
      const response = await axios.post(
        `${BASE_URL}/api/xero/create-invoice/${authData.userId}`,
        invoices
      );
      if (response.data.success) {
        toast.success(response.data.msg || 'Invoices sent successfully');
        setLoading(false);
      }
      console.log("Invoice creation response:", response.data);
    } catch (err) {
      console.log('error: ' , err)
      toast.error(err.response.data.msg || 'Error creating invoices')
    }
  };

  const sendNIASRenalInvoicesToXero = async () => {
    const invoices = {
      invoices: selectedShiftItems
        .map((invoice) => {
          console.log("Renal Invoice: ", invoice);
  
          const contactInfo = {
            contactID: "950e422a-4163-4ee9-b32d-b97649175a53", // Replace with actual contact ID for Renal transfers
          };
  
          const description = [
            `NIAS Renal Transfer`,
            `TRUST: ${invoice.jobDetails.trust}`,
            `Cost Code: ${invoice.jobDetails.costCenter}`,
            `Call Sign: ${invoice.jobDetails.callSign}`,
            `DATE: ${formatDateTime(invoice.jobDetails.jobStartDate)}`,
            `Total Miles: ${invoice.jobs.totalMiles.toFixed(2)}`,
            `NIAS Reference Number: ${invoice.jobs.journeys[0].niasReference}`,
            `Job Reference Number: ${invoice.jobDetails.referenceCode.toUpperCase()}`,
          ].join("\n");
  
          const unitAmount = parseFloat(invoice.jobDetails.price);
          const lineAmount = parseFloat(invoice.jobDetails.price);
  
          if (isNaN(unitAmount) || isNaN(lineAmount)) {
            console.error("Invalid amount detected in renal invoice:", invoice);
            return null;
          }
  
          return {
            type: "ACCREC",
            invoiceNumber: invoice.jobDetails.referenceCode,
            contact: contactInfo,
            lineItems: [
              {
                description: description,
                quantity: 1.0,
                unitAmount: unitAmount,
                accountCode: "201", // Replace with the actual account code for Renal invoices
                taxType: "NONE",
                lineAmount: lineAmount,
              },
            ],
            date: moment(invoice.jobDetails.jobStartDate).format("YYYY-MM-DD"),
            dueDate: moment(invoice.jobDetails.jobStartDate)
              .add(30, "days")
              .format("YYYY-MM-DD"),
            reference: invoice.jobDetails.referenceCode,
            status: "DRAFT",
          };
        })
        .filter((invoice) => invoice !== null), // Filter out invalid invoices
    };
  
    try {
      const response = await axios.post(
        `${BASE_URL}/api/xero/create-invoice/${authData.userId}`,
        invoices
      );
      if (response.data.success) {
        toast.success("🔥 Renal invoices have been successfully sent to Xero.");
        setLoading(false);
      }
      console.log("Renal Invoice creation response:", response.data);
    } catch (err) {
      console.error("Error sending Renal invoices:", err);
    }
  };

  const downloadCSV = () => {
    if (activeTab === "Journey") {
      SendInvoicesToXero();
    } else if (activeTab === "Shift") {
      sendNIASInvoicesToXero();
    } else if (activeTab === "NIASRenal") {
      sendNIASRenalInvoicesToXero();
    }
  };

  const clearInvoices = () => {
    setInvoices([]);
  };

  const SendInvoicesToXero = async () => {
    const invoices = {
      invoices: selectedItems
        .map((invoice) => {
          const pickupLocation =
            invoice.job.journeyDetails.journeys[0].pickupLocation === "Other"
              ? invoice.job.journeyDetails.journeys[0].otherPickupLocation
              : invoice.job.journeyDetails.journeys[0].pickupLocation;
          const dropOffLocation =
            invoice.job.journeyDetails.journeys[0].dropOffLocation === "Other"
              ? invoice.job.journeyDetails.journeys[0].otherDropOffLocation
              : invoice.job.journeyDetails.journeys[0].dropOffLocation;

          const nameTrimmed = invoice.job.patientDetails.name.trim();
          const initials = nameTrimmed
            .split(" ")
            .map((namePart) => namePart[0])
            .join("");

          const dob = invoice.job.patientDetails.dob
            ? new Date(invoice.job.patientDetails.dob).toLocaleDateString(
                "en-GB"
              )
            : "NO DOB";

          const description = [
            `${
              invoice.job.JobStatus === 7
                ? "Cancelled On Ward (COW)"
                : "Ambulance Transfer"
            }`,
            `TRUST: ${invoice.job.referrerDetails.trustLocation}`,
            `FROM: ${pickupLocation} , ${invoice.job.journeyDetails.journeys[0].pickUpLocationArea}`,
            `TO: ${dropOffLocation} , ${invoice.job.journeyDetails.journeys[0].dropOffLocationArea}`,
            `DATE: ${formatDateTime(
              invoice.job.journeyDetails.journeys[0].dateTimePickup
            )}`,
            `MILEAGE: ${invoice.job.journeyDetails.distance}`,
            `DOB: ${dob}`,
            `INITIALS: ${initials}`,
            `Assigned to: ${invoice.assignedDriversUsername.toUpperCase()}`,
          ].join("\n");

          const unitAmount = parseFloat(invoice.job.journeyDetails.price);
          const lineAmount = parseFloat(invoice.job.journeyDetails.price);

          if (isNaN(unitAmount) || isNaN(lineAmount)) {
            console.error("Invalid amount detected in invoice:", invoice);
            return null;
          }

          let contactID = "";

          if (invoice.job.referrerDetails.trustLocation.includes("Northern")) {
            contactID = "e7d5bed1-86e7-4690-b130-3b5f7ed3c83f";
          } else if (
            invoice.job.referrerDetails.trustLocation.includes("Western")
          ) {
            contactID = "e246a269-38d1-46a4-b04e-c5e58cd1a241";
          }

          return {
            type: "ACCREC",
            invoiceNumber: invoice.job.referenceCode.toUpperCase(),
            contact: {
              contactID: contactID,
            },
            lineItems: [
              {
                description: description,
                quantity: 1.0,
                unitAmount: unitAmount,
                accountCode: "201",
                taxType: "NONE",
                lineAmount: lineAmount,
                inventoryItemCode: "Patient Transfer Services",
              },
            ],
            date: moment(
              invoice.job.journeyDetails.journeys[0].dateTimePickup
            ).format("YYYY-MM-DD"),
            dueDate: moment(
              invoice.job.journeyDetails.journeys[0].dateTimePickup
            )
              .add(30, "days")
              .format("YYYY-MM-DD"),
            reference: invoice.job.referenceCode.toUpperCase(),
            status: "DRAFT",
          };
        })
        .filter((invoice) => invoice !== null), // Filter out any invalid invoices
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/api/xero/create-invoice/${authData.userId}`,
        invoices
      );
      if (response.data.success) {
        toast.success("🔥 Invoices have been successfully sent to xero.");
        setLoading(false);
      }
      console.log("Invoice creation response:", response.data);
    } catch (err) {
      console.error("Error sending invoices:", err);
    }
  };

  const getTrustContactID = (trust) => {
    if (!trust) return null; // Return null if trust is undefined or null
  
    const lowercaseTrust = trust.toLowerCase();
  
    switch (lowercaseTrust) {
      case "northern":
        return "e7d5bed1-86e7-4690-b130-3b5f7ed3c83f"; // Replace with the actual ID for Trust A
      case "western":
        return "e246a269-38d1-46a4-b04e-c5e58cd1a241"; // Replace with the actual ID for Trust B
      case "belfast":
        return "ID789"; // Replace with the actual ID for Trust C
      case "south east":
      case "south eastern":
        return "ID789"; // Replace with the actual ID for Trust C
      default:
        return null; // Return null if no matching trust is found
    }
  };

  return (
    <div className="px-10 my-8">
      <div>
        <div className="flex flex-row justify-between">
          <p className="font-bold text-2xl">{`Invoicing (${
            invoices ? invoices.length : ""
          })`}</p>
          <div className="flex flex-row items-center gap-4">
            <Select
              value={activeTab}
              onValueChange={(value) => {
                clearInvoices();
                setActiveTab(value);
              }}
            >
              <SelectTrigger>{activeTab}</SelectTrigger>
              <SelectContent>
                <SelectItem value="Journey">Journey</SelectItem>
                <SelectItem value="Shift">Shift</SelectItem>
                <SelectItem value="NIASRenal">NIAS Renal</SelectItem>
              </SelectContent>
            </Select>
            <DatePickerWithRange
              className="my-datepicker"
              onChange={handleDateChange}
            />
            <Button
              onClick={() => getInvoices()}
              className="font-bold text-2xl"
            >
              Get Invoices
            </Button>
            <Button
              onClick={() => downloadCSV()}
              className="font-bold text-2xl"
            >
              Import to Xero
            </Button>
          </div>
        </div>
        <div className="w-full overflow-x-scroll md:overflow-x-hidden mt-4">
          <div className="mb-4 flex">
            <input
              type="text"
              placeholder="Search by name or reference code"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400"
            />
          </div>
          {activeTab === "Journey" && (
            <InvoicingJourney
              loading={loading}
              invoices={invoices}
              onSelectedItemsChange={handleSelectedItemsChange}
            />
          )}
          {activeTab === "Shift" && (
            <InvoicingShift
              loading={loading}
              invoices={invoices}
              onSelectedItemsChange={handleSelectedShiftItemsChange}
            />
          )}
          {activeTab === "NIASRenal" && (
            <InvoicingRenal
              loading={loading}
              invoices={invoices}
              onSelectedItemsChange={handleSelectedShiftItemsChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoicingPanel;
