import React, { useEffect, useState } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ViewEventsDetails from './Components/Add Job/Events/View Events Full/ViewFullEventsDetails';
import ViewRenalDetails from './Components/Add Job/Renal/View Renal Full/ViewFullRenalJob';
import ViewRepatriationDetails from './Components/Add Job/Repatriation/View Repatriation Full/ViewFullRepatriationDetails';
import DriverFullDetails from './Components/Drivers/DriverFullDetails';
import DisplayLogs from './Components/Drivers/Logs/DisplayLogs';
import NavBar from './Components/Global/Navbar';
import { AuthProvider, useAuth } from './Context/AuthProvider';
import AddAmbulance from './Screens/AddAmbulance';
import InvoicingPanel from './Screens/Admin/InvoicingPanel';
import ReportingScreen from './Screens/Admin/Reports';
import DispatchCalendar from './Screens/Calendar';
import Dashboard from './Screens/Dashboard';
import DriversPage from './Screens/Driver';
import FullOverviewScreen from './Screens/FullDetails';
import AddEventsJob from './Screens/Jobs/AddEventJob';
import AddJourneyJob from './Screens/Jobs/AddJourneyJob'
import AddNIASRenalJob from './Screens/Jobs/AddRenalJob';
import AddRepatriationJob from './Screens/Jobs/AddRepatriationJob';
import AddShiftJob from './Screens/Jobs/AddShiftJob'
import LoginScreen from './Screens/LoginScreen';
import NIASJobs from './Screens/NIASJobs';
import ViewNIASJob from './Screens/NIASJobView';
import SettingsPage from './Screens/Settings/SettingsPage';
import StaffList from './Screens/StaffList';

import 'react-toastify/dist/ReactToastify.css';
import Scheduler from './Screens/Scheduler';

function App() {
  
  const { authData } = useAuth();
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (authData.token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [authData]);

  const AuthRoutes = () => (
    <div className='mt-6'>
      <NavBar />
      <Switch>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route exact path="/full-overview">
          <FullOverviewScreen />
        </Route>
        <Route exact path="/add-journey-job">
          <AddJourneyJob />
        </Route>
        <Route exact path="/view-repatriation-job/:JobID">
          <ViewRepatriationDetails />
        </Route>
        <Route exact path="/view-nias-renal-job/:JobID">
          <ViewRenalDetails />
        </Route>
        <Route exact path="/add-shift-job">
          <AddShiftJob />
        </Route>
        <Route exact path="/add-repatriation-job">
          <AddRepatriationJob />
        </Route>
        <Route exact path="/add-nias-renal-job">
          <AddNIASRenalJob />
        </Route>
        <Route exact path="/add-events-job">
          <AddEventsJob />
        </Route>
        <Route exact path="/view-events-job/:JobID">
          <ViewEventsDetails />
        </Route>
        <Route exact path="/view-nias-job">
          <ViewNIASJob />
        </Route>
        <Route exact path="/drivers/add-vehicle">
          <AddAmbulance />
        </Route>
        <Route exact path="/drivers/:driverId">
          <DriverFullDetails />
        </Route>
        <Route exact path="/drivers/:driverId/log/:logID">
          <DisplayLogs />
        </Route>
        <Route path="/drivers">
          <DriversPage />
        </Route>
        <Route path="/staff">
          <StaffList />
        </Route>
        <Route path="/settings">
          <SettingsPage />
        </Route>
        <Route path="/admin/invoicing">
          <InvoicingPanel />
        </Route>
        <Route path="/admin/reporting">
          <ReportingScreen/>
        </Route>
        <Route path="/calendar">
          <DispatchCalendar/>
        </Route>
        <Route path="/scheduler">
          <Scheduler/>
        </Route>
        <Redirect from="/login" to="/dashboard" />
        <Redirect exact from="/" to="/dashboard" />
      </Switch>
    </div>
  );

  const NonAuthRoutes = () => (
    <Switch>
      <Route path="/login">
        <LoginScreen />
      </Route>
      <Redirect from="/dashboard" to="/login" />
      <Redirect from="/full-overview" to="/login" />
      <Redirect exact from="/" to="/login" />
    </Switch>
  );

  return (
    <Router>
      {authenticated ? <AuthRoutes /> : <NonAuthRoutes />}
      <ToastContainer />
    </Router>
  );
}

function AppWithRouter() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWithRouter;
