import { Label } from "@radix-ui/react-label";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import { Button } from "../Global/UI/Button";
import { Input } from "../Global/UI/Input";

const JobCard = ({ job, onJobDataChange, onRemoveJob, onAddReturnJob }) => {
  const [distanceInMiles, setDistanceInMiles] = useState("");

  const calculateDistance = async (pickupAddress, dropOffAddress) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/settings/locations/calculateDistances`,
        {
          pickupAddress: pickupAddress,
          dropOffAddress: dropOffAddress,
        }
      );
      return response.data.distance;
    } catch (error) {
      console.error("Error calculating distance:", error);
      return "";
    }
  };

  const handleSelectStartingAddress = async (address) => {
    onJobDataChange(job.id, "startingAddress", address);
    if (job.endingAddress) {
      const miles = await calculateDistance(address, job.endingAddress);
      onJobDataChange(job.id, "miles", miles);
    }
  };

  const handleSelectEndingAddress = async (address) => {
    onJobDataChange(job.id, "endingAddress", address);
    if (job.startingAddress) {
      const miles = await calculateDistance(job.startingAddress, address);
      onJobDataChange(job.id, "miles", miles);
    }
  };

  const handleCreateReturn = async () => {
    const returnJob = {
      ...job,
      id: `${job.id + 1}`,
      startingAddress: job.endingAddress,
      endingAddress: job.startingAddress,
      miles: job.miles || "",
      referenceNumber: job.referenceNumber || "",
    };
    onAddReturnJob(returnJob);
  };

  return (
    <div className="border p-4 my-4 flex flex-col">
      <div className="flex flex-row justify-between w-full">
        <div></div>
        <div className="flex flex-row gap-4">
          <Button onClick={handleCreateReturn}>Create Return</Button>
          <button onClick={() => onRemoveJob(job.id)}>X</button>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 items-center mb-4">
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`miles${job.id}`}>Patient Name</Label>
          <Input
            type="text"
            value={job.patientName}
            onChange={(e) =>
              onJobDataChange(job.id, "patientName", e.target.value)
            }
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`miles${job.id}`}>Patient DOB</Label>
          <Input
            id={`dob${job.id}`}
            type="date"
            value={
              job.patientDOB
                ? moment(job.patientDOB, "DD/MM/YYYY").format("YYYY-MM-DD")
                : ""
            } // Convert from API format to HTML input format
            onChange={(e) =>
              onJobDataChange(
                job.id,
                "patientDOB",
                moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY")
              )
            } // Convert back to API format on change
            placeholder="DD/MM/YYYY"
            className="w-full"
          />
        </div>
        <div className="grid w-full max-w-sm gap-1.5">
          <Label htmlFor={`startingAddress${job.id}`}>Starting Address</Label>
          <PlacesAutocomplete
            value={job.startingAddress}
            onChange={(address) =>
              onJobDataChange(job.id, "startingAddress", address)
            }
            onSelect={handleSelectStartingAddress}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Type your pickup address...",
                    className:
                      "w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400",
                  })}
                />
                <div>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { style })}
                        key={suggestion.placeId}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="grid w-full max-w-sm gap-1.5">
          <Label htmlFor={`endingAddress${job.id}`}>Ending Address</Label>
          <PlacesAutocomplete
            value={job.endingAddress}
            onChange={(address) =>
              onJobDataChange(job.id, "endingAddress", address)
            }
            onSelect={handleSelectEndingAddress}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: "Type your pickup address...",
                    className:
                      "w-full p-2 border bg-white border-gray-300 rounded focus:ring-2 focus:ring-inset focus:ring-teal-400",
                  })}
                />
                <div>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const style = {
                      backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                    };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, { style })}
                        key={suggestion.placeId}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`miles${job.id}`}>Miles</Label>
          <Input
            type="text"
            id={`miles${job.id}`}
            value={job.miles}
            onChange={(e) => onJobDataChange(job.id, "miles", e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-1.5">
          <Label htmlFor={`referenceNumber${job.id}`}>Reference Number</Label>
          <Input
            type="text"
            id={`referenceNumber${job.id}`}
            value={job.referenceNumber}
            onChange={(e) =>
              onJobDataChange(job.id, "referenceNumber", e.target.value)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default JobCard;
