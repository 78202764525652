import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Button } from "../Components/Global/UI/Button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../Components/Global/UI/Dialog";
import { Input } from "../Components/Global/UI/Input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../Components/Global/UI/Select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../Components/Global/UI/table";
// Import ShadCN Select
import { BASE_URL } from "./Settings/DASHBOARD_SETTINGS";

const StaffList = () => {
  const [staffList, setStaffList] = useState([]);
  const [selectedStaffIds, setSelectedStaffIds] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [newStaff, setNewStaff] = useState({ first_name: "", last_name: "" });
  const [sortBy, setSortBy] = useState("last_name"); // Default sorting field
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);

  useEffect(() => {
    fetchStaff();
  }, [sortBy, sortOrder]); // Refetch staff list when sorting options change

  const fetchStaff = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/staff`, {
        params: { sortBy, order: sortOrder },
      });
      if (response.data.success) {
        setStaffList(response.data.staff);
      } else {
        toast.error(response.data.error || "Failed to fetch staff.");
      }
    } catch (error) {
      toast.error("Error fetching staff.");
      console.error("Error fetching staff:", error);
    }
  };

  const addStaff = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/staff/add`, newStaff);
      if (response.data.success) {
        toast.success(response.data.message);
        setNewStaff({ first_name: "", last_name: "" });
        setShowAddDialog(false);
        fetchStaff();
      } else {
        toast.error(response.data.error || "Failed to add staff.");
      }
    } catch (error) {
      toast.error("Error adding staff.");
      console.error("Error adding staff:", error);
    }
  };

  const updateStaff = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/staff/update/${selectedStaff._id}`,
        selectedStaff
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setShowEditDialog(false);
        fetchStaff();
      } else {
        toast.error(response.data.error || "Failed to update staff.");
      }
    } catch (error) {
      toast.error("Error updating staff.");
      console.error("Error updating staff:", error);
    }
  };

  const deleteStaff = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/staff/delete`, { ids: [selectedStaff._id] });
      if (response.data.success) {
        toast.success(response.data.message);
        setShowDeleteDialog(false);
        fetchStaff();
      } else {
        toast.error(response.data.error || "Failed to delete staff.");
      }
    } catch (error) {
      toast.error("Error deleting staff.");
      console.error("Error deleting staff:", error);
    }
  };

  const deleteSelectedStaff = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/staff/delete`, { ids: selectedStaffIds });
      if (response.data.success) {
        toast.success(response.data.message);
        setSelectedStaffIds([]);
        fetchStaff();
      } else {
        toast.error(response.data.error || "Failed to delete selected staff.");
      }
    } catch (error) {
      toast.error("Error deleting selected staff.");
      console.error("Error deleting selected staff:", error);
    }
  };

  const toggleSelectAll = (e) => {
    if (e.target.checked) {
      const allIds = staffList.map((staff) => staff._id);
      setSelectedStaffIds(allIds);
    } else {
      setSelectedStaffIds([]);
    }
  };

  const toggleSelectRow = (id) => {
    setSelectedStaffIds((prev) =>
      prev.includes(id) ? prev.filter((staffId) => staffId !== id) : [...prev, id]
    );
  };

  return (
    <div className="p-10">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-6 gap-4">
        <h3 className="text-2xl font-semibold">{`Staff (${staffList.length})`}</h3>
        <div className="flex items-center gap-4">
          <Button
            variant="destructive"
            onClick={deleteSelectedStaff}
            disabled={selectedStaffIds.length === 0}
          >
            Delete Selected
          </Button>
          <Dialog>
            <DialogTrigger asChild>
              <Button onClick={() => setShowAddDialog(true)}>Add Staff</Button>
            </DialogTrigger>
            <DialogContent open={showAddDialog} onOpenChange={setShowAddDialog}>
              <DialogHeader>
                <DialogTitle>Add New Staff</DialogTitle>
                <DialogDescription>
                  Enter the details of the new staff member.
                </DialogDescription>
              </DialogHeader>
              <div className="space-y-4">
                <Input
                  placeholder="First Name"
                  value={newStaff.first_name}
                  onChange={(e) =>
                    setNewStaff({ ...newStaff, first_name: e.target.value })
                  }
                />
                <Input
                  placeholder="Last Name"
                  value={newStaff.last_name}
                  onChange={(e) =>
                    setNewStaff({ ...newStaff, last_name: e.target.value })
                  }
                />
              </div>
              <DialogFooter>
                <Button
                  variant="secondary"
                  onClick={() => setShowAddDialog(false)}
                >
                  Cancel
                </Button>
                <Button onClick={addStaff}>Add Staff</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>

      {/* Sorting Dropdown */}
      <div className="flex items-center gap-4 mb-6">
        <Select value={sortBy} onValueChange={setSortBy}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Sort By" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="first_name">First Name</SelectItem>
            <SelectItem value="last_name">Last Name</SelectItem>
          </SelectContent>
        </Select>
        <Select value={sortOrder} onValueChange={setSortOrder}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Sort Order" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="asc">Ascending</SelectItem>
            <SelectItem value="desc">Descending</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>
              <input
                type="checkbox"
                onChange={toggleSelectAll}
                checked={selectedStaffIds.length === staffList.length && staffList.length > 0}
              />
            </TableHead>
            <TableHead>First Name</TableHead>
            <TableHead>Last Name</TableHead>
            <TableHead>Full Name</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {staffList.map((staff) => (
            <TableRow key={staff._id}>
              <TableCell>
                <input
                  type="checkbox"
                  checked={selectedStaffIds.includes(staff._id)}
                  onChange={() => toggleSelectRow(staff._id)}
                />
              </TableCell>
              <TableCell>{staff.first_name}</TableCell>
              <TableCell>{staff.last_name}</TableCell>
              <TableCell>{staff.full_name}</TableCell>
              <TableCell>
                <div className="flex gap-2">
                  <Button
                    onClick={() => {
                      setSelectedStaff(staff);
                      setShowEditDialog(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      setSelectedStaff(staff);
                      setShowDeleteDialog(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Edit Dialog */}
      <Dialog open={showEditDialog} onOpenChange={setShowEditDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Staff</DialogTitle>
            <DialogDescription>
              Update the staff member's details below.
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <Input
              placeholder="First Name"
              value={selectedStaff?.first_name || ""}
              onChange={(e) =>
                setSelectedStaff({
                  ...selectedStaff,
                  first_name: e.target.value,
                })
              }
            />
            <Input
              placeholder="Last Name"
              value={selectedStaff?.last_name || ""}
              onChange={(e) =>
                setSelectedStaff({
                  ...selectedStaff,
                  last_name: e.target.value,
                })
              }
            />
          </div>
          <DialogFooter>
            <Button
              variant="secondary"
              onClick={() => setShowEditDialog(false)}
            >
              Cancel
            </Button>
            <Button onClick={updateStaff}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete {selectedStaff?.full_name}?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteDialog(false)}
            >
              Cancel
            </Button>
            <Button variant="destructive" onClick={deleteStaff}>
              Confirm Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StaffList;