import { Label } from "@radix-ui/react-label";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import { Combobox } from "../Global/UI/Combobox";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../Global/UI/Select";

const ShiftJobTypes = [
  { value: "Paramedic", label: "Paramedic" },
  { value: "EMT", label: "EMT" },
  { value: "ACA", label: "ACA" },
];

const StaffCard = ({ staff, onStaffNameChange, onRemoveStaff, onJobTypeChange }) => {
  const [staffOptions, setStaffOptions] = useState([]);

  // Fetch staff members on component mount
  useEffect(() => {
    const fetchStaff = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/staff`);
        if (response.data.success) {
          setStaffOptions(
            response.data.staff.map((staff) => ({
              value: staff.full_name,
              label: staff.full_name,
            }))
          );
        } else {
          console.error("Failed to fetch staff:", response.data.error);
        }
      } catch (error) {
        console.error("Error fetching staff:", error);
      }
    };

    fetchStaff();
  }, []);

  const handleDriverChange = (value) => {
    const selectedStaff = staffOptions.find((item) => item.value === value);
    if (selectedStaff) {
      onStaffNameChange(staff.id, selectedStaff.label); // Update staff name in parent component
    }
  };

  return (
    <div className="border p-4 my-4 flex flex-col">
      <div className="flex flex-row justify-between w-full">
        <div></div>
        <button
          className="text-red-500 font-bold"
          onClick={() => onRemoveStaff(staff.id)}
        >
          X
        </button>
      </div>
      <div className="grid grid-cols-2 gap-4 items-center mb-4">
        {/* Driver Selection */}
        <div className="grid w-full max-w-sm gap-1.5">
          <Label htmlFor={`staffName${staff.id}`}>Staff</Label>
          <Combobox
            data={staffOptions} // Dynamically loaded staff options
            placeholder="Select a staff member..."
            value={staff.name}
            onValueChange={handleDriverChange} // Handle driver selection
          />
        </div>
        {/* Job Role Selection */}
        <div className="grid w-full max-w-sm gap-1.5">
          <Label htmlFor={`jobRole${staff.id}`}>Job Role</Label>
          <Select
            value={staff.jobType}
            onValueChange={(value) => onJobTypeChange(staff.id, value)}
          >
            <SelectTrigger>{staff.jobType ? staff.jobType : "Select Role"}</SelectTrigger>
            <SelectContent>
              {ShiftJobTypes.map((item) => (
                <SelectItem key={item.value} value={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default StaffCard;