import { Label } from "@radix-ui/react-label";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Logo from "../../Assets/Images/JaceLOGO2.png";
import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import { Button, buttonVariants } from "../Global/UI/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../Global/UI/Dialog";
import { Input } from "../Global/UI/Input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../Global/UI/table";

const DisplayDrivers = () => {
  const [drivers, setDrivers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState("");

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/drivers/getDrivers`);

        const sortedDrivers = response.data.drivers.sort((a, b) => {
          const usernameA = a.username.match(/(\d+)/) ? parseInt(a.username.match(/(\d+)/)[0]) : a.username;
          const usernameB = b.username.match(/(\d+)/) ? parseInt(b.username.match(/(\d+)/)[0]) : b.username;

          if (usernameA < usernameB) return -1;
          if (usernameA > usernameB) return 1;
          return 0;
        });

        setDrivers(sortedDrivers);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching drivers:", error.message);
      }
    };

    fetchData();
  }, []);

  const handleViewDriver = (driver) => {
    history.push({
      pathname: `/drivers/${driver.driverId}`,
      state: { driver },
    });
  };

  const handleCreateAccount = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/drivers/register`, {
        username,
        password,
      });

      if (response.data.username) {
        toast.success("User created successfully!");
        handleCloseAddDialog();
        setDrivers((prev) => [...prev, response.data]); // Add new driver to the list
      } else {
        toast.error("Failed to create the user. Please try again.");
      }
      setLoading(false);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Something went wrong. Please try again."
      );
      setLoading(false);
    }
  };

  const handleEditDriver = (driver) => {
    setSelectedDriver(driver);
    setUsername(driver.username);
    setRegistrationNumber(driver.registrationNumber || "");
    setIsEditDialogOpen(true);
  };

  const handleUpdateDriver = async () => {
    if (!selectedDriver) return;

    try {
      const response = await axios.post(`${BASE_URL}/api/drivers/update-driver/${selectedDriver.driverId}`, {
        username,
        registrationNumber,
      });

      if (response.data.success) {
        toast.success("Driver updated successfully!");
        handleCloseEditDialog();
        setDrivers((prev) =>
          prev.map((driver) =>
            driver.driverId === selectedDriver.driverId
              ? { ...driver, username, registrationNumber }
              : driver
          )
        );
      } else {
        toast.error("Failed to update the driver.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Something went wrong. Please try again."
      );
    }
  };

  const handleAddDriver = () => {
    setIsAddDialogOpen(true);
  };

  const handleCloseAddDialog = () => {
    setIsAddDialogOpen(false);
    setUsername("");
    setPassword("");
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedDriver(null);
    setUsername("");
    setRegistrationNumber("");
  };

  return (
    <div className="w-full p-10">
      {/* Add Driver Modal */}
      <Dialog open={isAddDialogOpen} onOpenChange={handleCloseAddDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add a Driver</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <DialogDescription>Add a new driver to the system.</DialogDescription>
          <div className="max-w-lg">
            <Label htmlFor="username">Username:</Label>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              id="username"
              name="username"
            />
          </div>
          <div className="mb-4 max-w-lg">
            <Label htmlFor="password">Password:</Label>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="text"
              id="password"
              name="password"
            />
          </div>
          <Button
            variant="default"
            size="lg"
            className={buttonVariants({ variant: "default", size: "lg" })}
            onClick={handleCreateAccount}
          >
            {loading ? "Submitting..." : "Register Driver"}
          </Button>
        </DialogContent>
      </Dialog>

      {/* Edit Driver Modal */}
      <Dialog open={isEditDialogOpen} onOpenChange={handleCloseEditDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Driver</DialogTitle>
            <DialogClose />
          </DialogHeader>
          <DialogDescription>Edit driver details below.</DialogDescription>
          <div className="max-w-lg">
            <Label htmlFor="username">Username:</Label>
            <Input
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              id="username"
              name="username"
            />
          </div>
          <div className="mb-4 max-w-lg">
            <Label htmlFor="registrationNumber">Registration Number:</Label>
            <Input
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
              type="text"
              id="registrationNumber"
              name="registrationNumber"
            />
          </div>
          <Button
            variant="default"
            size="lg"
            className={buttonVariants({ variant: "default", size: "lg" })}
            onClick={handleUpdateDriver}
          >
            Update Driver
          </Button>
        </DialogContent>
      </Dialog>

      {/* Table */}
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-3xl font-semibold">Drivers</h2>
        <Button
          variant="default"
          size="default"
          className={buttonVariants({ variant: "default", size: "default" })}
          onClick={handleAddDriver}
        >
          Add a Driver
        </Button>
      </div>
      <div className="w-full">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Registration Number</TableHead>
              <TableHead>Driver ID</TableHead>
              <TableHead>Assigned Jobs</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading
              ? Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="h-[18px] w-[100px] bg-gray-300 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-[18px] w-[50px] bg-gray-300 animate-pulse"></div>
                    </TableCell>
                    <TableCell>
                      <div className="h-[18px] w-[100px] bg-gray-300 animate-pulse"></div>
                    </TableCell>
                  </TableRow>
                ))
              : drivers.map((driver, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <img
                          src={Logo}
                          className="h-[30px] w-[30px] rounded-full"
                          alt=""
                        />
                        <p className="text-md font-semibold text-gray-600">{`@${driver.username}`}</p>
                      </div>
                    </TableCell>
                    <TableCell>{driver.registrationNumber}</TableCell>
                    <TableCell>{driver.driverId}</TableCell>
                    <TableCell>{driver.assignedJobsCount}</TableCell>
                    <TableCell>
                      <Button
                        variant="default"
                        size="default"
                        className="mr-2"
                        onClick={() => handleViewDriver(driver)}
                      >
                        View Driver
                      </Button>
                      <Button
                        variant="default"
                        size="default"
                        onClick={() => handleEditDriver(driver)}
                      >
                        Edit Driver
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default DisplayDrivers;