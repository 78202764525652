import { Popover } from "@headlessui/react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";

import Logo from "../../Assets/Images/JaceLOGO2.png";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../Components/Global/UI/table";
import { useAuth } from "../../Context/AuthProvider";
import { BASE_URL } from "../../Screens/Settings/DASHBOARD_SETTINGS";
import { Button, buttonVariants } from "../Global/UI/Button";
import { Calendar } from "../Global/UI/Calender";
import { Checkbox } from "../Global/UI/checkbox";
import { DatePickerWithRange } from "../Global/UI/DateRangePicker";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../Global/UI/Dialog";
import { Pagination } from "../Global/UI/Pagination";

const NiasJobList = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { authData } = useAuth();
  const isAdmin = authData.role.toString() === "1";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [referenceCode, setReferenceCode] = useState(""); // New state for reference code

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchData = async (page = currentPage, startDate, endDate) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/nias/job/get-all`, {
        params: {
          page,
          limit: pageSize,
          startDate: startDate || dateRange.startDate,
          endDate: endDate || dateRange.endDate,
          referenceCode: referenceCode || undefined
        },
      });
      if (response.data.success) {
        setLoading(false);
        setItems(response.data.results);
        setTotalPages(response.data.pagination.totalPages);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        error.response?.data?.message || "An unknown error has occurred"
      );
      console.error(error);
    }
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    fetchData(1, startDate, endDate);
  };

  const history = useHistory();

  const handleViewClick = (item) => {
    history.push({
      pathname: "/view-nias-job",
      state: { item: item },
    });
  };

  const getCostCenterName = (costCenter) => {
    switch (costCenter) {
      case "FCAE01":
        return "EAC";
      case "FCPE01":
        return "NEAC";
      case "Trust":
        return "Trust";
      default:
        return "N/A";
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const handleSelectItem = (itemId) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const handleSelectAll = () => {
    const allIds = items.map((item) => item._id);
    if (selectedItems.length === items.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allIds);
    }
  };

  const handleRemoveJobs = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/nias/job/delete-nias-jobs`,
        {
          jobIds: selectedItems,
        }
      );

      if (response.data.success) {
        toast.success(response.data.msg);
        setSelectedItems([]);
        fetchData();
        setIsDialogOpen(false);
      } else {
        toast.error(response.data.msg || "Failed to delete jobs.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unknown error has occurred"
      );
      console.error(error);
    }
  };

  useEffect(() => {
    const savedState = JSON.parse(localStorage.getItem("NIAS_TAB"));
    if (savedState) {
      const { currentPage: savedPage, dateRange: savedDateRange } = savedState;
      setCurrentPage(savedPage || 1);
      setDateRange(savedDateRange || { startDate: null, endDate: null });
      fetchData(savedPage, savedDateRange?.startDate, savedDateRange?.endDate);
    } else {
      fetchData();
    }
  }, []);

  // Save current state to localStorage whenever it changes
  useEffect(() => {
    const stateToSave = {
      currentPage,
      dateRange,
    };
    localStorage.setItem("NIAS_TAB", JSON.stringify(stateToSave));
  }, [currentPage, dateRange]);

  const handleSetDateToToday = () => {
    const today = moment().format("YYYY-MM-DD");
    handleDateRangeChange(today, today);
  };

  const handleSetDateToTomorrow = () => {
    const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
    handleDateRangeChange(tomorrow, tomorrow);
  };

  const openInPlanday = (item) => {
    console.log(item)
    const companyDomain = "jacemedical"; // Replace with your company's Planday domain
    const shiftId = item.planday.jobID || ""; // Ensure the job object has a `plandayId` property
    const startDate = item.shiftDetails?.startDate || moment().startOf("week").format("YYYY-MM-DD");
    const endDate = item.shiftDetails?.endDate || moment().endOf("week").format("YYYY-MM-DD");
  
    const plandayUrl = `https://${companyDomain}.planday.com/schedule#/scheduling/5680/board/roles/shift/${shiftId}?endDate=${endDate}&startDate=${startDate}`;
    
    window.open(plandayUrl, "_blank");
  };

  useEffect(() => {
    fetchData(1);
  }, [referenceCode]);

  return (
    <div className="w-full overflow-x-auto my-4">
      <div className="mb-6 flex justify-between items-center">
        <h2 className="text-lg font-bold">Actions</h2>
        <div className="flex flex-row gap-4 items-center">
        <div className="flex items-center gap-2">
            <input
              type="text"
              placeholder="Enter Reference Code"
              value={referenceCode}
              onChange={(e) => setReferenceCode(e.target.value)}
              className="border border-gray-300 rounded-md p-2"
            />
          </div>
          <Button
            variant="default"
            onClick={handleSetDateToToday}
            className={buttonVariants({
              variant: "default",
            })}
          >
            Today
          </Button>
          <Button
            variant="default"
            onClick={handleSetDateToTomorrow}
            className={buttonVariants({
              variant: "default",
            })}
          >
            Tomorrow
          </Button>
          {selectedItems.length > 0 && (
            <Button
              variant="destructive"
              onClick={() => setIsDialogOpen(true)}
              className={buttonVariants({
                variant: "destructive",
              })}
            >
              Remove Jobs
            </Button>
          )}
          <DatePickerWithRange
            onChange={(selectedDate) => {
              const { from, to } = selectedDate || {};
              const startDate = from ? format(from, "yyyy-MM-dd") : null;
              const endDate = to ? format(to, "yyyy-MM-dd") : null;
              handleDateRangeChange(startDate, endDate);
            }}
          />
        </div>
      </div>
      {loading ? (
        <div className="grid grid-cols-1 md:grid-cols-[500px 1fr 500px 1fr] gap-4 mt-4">
          {[...Array(15)].map((_, index) => (
            <div
              key={index}
              className="overflow-hidden border border-gray-200 rounded-md animate-pulse"
            >
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
              <div className="h-px bg-gray-300"></div>
              <div className="bg-gray-200 h-8 w-full"></div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="w-full">
            <Table className="w-full border-collapse">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <Checkbox
                      checked={
                        selectedItems.length === items.length &&
                        items.length > 0
                      }
                      onCheckedChange={handleSelectAll}
                      aria-label="Select all"
                    />
                  </TableHead>
                  <TableHead>Reference</TableHead>
                  <TableHead>Start Date</TableHead>
                  <TableHead>Start Time</TableHead>
                  <TableHead>Trust</TableHead>
                  <TableHead>Call Center</TableHead>
                  <TableHead>Starting Location</TableHead>
                  <TableHead>Call Sign</TableHead>
                  <TableHead>Staff</TableHead>
                  <TableHead>Mileage</TableHead>
                  {isAdmin && <TableHead>Price</TableHead>}
                  <TableHead>Actions</TableHead>

                </TableRow>
              </TableHeader>
              <TableBody>
                {items.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        checked={selectedItems.includes(item._id)}
                        onCheckedChange={() => handleSelectItem(item._id)}
                        aria-label={`Select ${item.jobDetails.trust}`}
                      />
                    </TableCell>
                    <TableCell>
                      {item.jobDetails.referenceCode || "N/A"}
                    </TableCell>
                    <TableCell>
                      <span>
                        {" "}
                        {new Date(
                          item.jobDetails.jobStartDate
                        ).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </span>
                    </TableCell>
                    <TableCell>
                      {item.shiftDetails.logonTime || "N/A"}
                    </TableCell>
                    <TableCell>
                      <span>{item.jobDetails.trust || "N/A"}</span>
                    </TableCell>
                    <TableCell>
                      {getCostCenterName(item.jobDetails.costCenter) || "N/A"}
                    </TableCell>
                    <TableCell>
                    {item.jobs.jobList ? item.jobs.jobList[0].startingAddress : "N/A"}
                    </TableCell>
                    <TableCell>{item.jobDetails.callSign || "N/A"}</TableCell>
                    <TableCell>
                      {item.staffDetails.staffList.map((staff, index) => (
                        <div key={index}>{staff.name}</div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <span>
                        {item.jobs.totalMiles
                          ? parseFloat(item.jobs.totalMiles).toFixed(2)
                          : "N/A"}
                      </span>
                    </TableCell>
                    {isAdmin && <TableCell>{item.jobDetails.price}</TableCell>}
                    <TableCell>
                      <div className="flex flex-row items-center gap-2">
                      <Button
                        variant="default"
                        size="default"
                        className={buttonVariants({
                          variant: "default",
                          size: "default",
                        })}
                        onClick={() => handleViewClick(item)}
                      >
                        View Job
                      </Button>
                      <Button
                        variant="default"
                        size="default"
                        className={buttonVariants({
                          variant: "default",
                          size: "default",
                        })}
                        onClick={() => openInPlanday(item)}
                      >
                        Open In Planday
                      </Button>
                      </div>
                     
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div className="mt-6 flex justify-center">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete the selected jobs? This action
              cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleRemoveJobs}>
              Confirm
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NiasJobList;
