import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import { BASE_URL } from "./Settings/DASHBOARD_SETTINGS";
import ListView from "../Components/Calendar/ListView";

const formatJobsToEvents = (jobs) => {
  return jobs
    .map((job) => {
      const startDate = new Date(job.jobStartDate);
      const [logonHour, logonMinute] = job.logonTime.split(":").map(Number);
      const [logoffHour, logoffMinute] = job.logoffTime.split(":").map(Number);

      console.log(job);

      console.log(logoffHour);

      const start = new Date(startDate);
      start.setHours(logonHour, logonMinute);

      const visualEnd = new Date(start);
      visualEnd.setMinutes(visualEnd.getMinutes() + 30);

      const realEnd = new Date(startDate);
      if (logoffHour < logonHour) {
        realEnd.setDate(realEnd.getDate() + 1);
      }
      realEnd.setHours(logoffHour, logoffMinute);

      if (isNaN(start.getTime()) || isNaN(visualEnd.getTime())) {
        console.error("Invalid event detected:", job);
        return null;
      }

      const trust = job.trust ? job.trust.toLowerCase() : "unknown";
      let backgroundColor;
      switch (trust) {
        case "belfast":
          backgroundColor = "#10B981";
          break;
        case "northern":
          backgroundColor = "#3B82F6";
          break;
        case "western":
          backgroundColor = "#EF4444";
          break;
        case "south eastern":
        case "south east":
          backgroundColor = "#F59E0B";
          break;
        default:
          backgroundColor = "#6B7280";
      }

      return {
        id: job.id,
        title: `${getTrustShortCode(job.trust)} | ${getCostCenterName(
          job.costCenter
        )} (${job.logonTime} - ${logoffHour
          .toString()
          .padStart(2, "0")}:${logoffMinute.toString().padStart(2, "0")})`,
        start: start.toISOString(),
        end: visualEnd.toISOString(),
        extendedProps: {
          backgroundColor,
          borderColor: "#000000",
          textColor: "#ffffff",
        },
      };
    })
    .filter((event) => event !== null);
};

const getCostCenterName = (costCenter) => {
  console.log(costCenter);
  switch (costCenter) {
    case "FCAE01":
      return "EAC";
    case "FCPE01":
      return "NEAC";
    case "Trust":
      return "Trust";
    default:
      return "N/A";
  }
};

const getTrustShortCode = (trust) => {
  switch (trust) {
    case "Northern":
      return "NT";
    case "Western":
      return "WT";
    case "Belfast":
      return "BT";
    case "south eastern":
    case "south east":
      return "SET";
    default:
      return "N/A";
  }
};

const DispatchCalendar = () => {
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("dayGridMonth"); // Track the current view
  const [listViewActive, setListViewActive] = useState(false); // Track if custom list view is active

  const history = useHistory();

  // const handleNavigation = () => {
  //   history.push({
  //     pathname: `/view-events-job/${jobID}`,
  //   });
  // }

  const fetchJobs = async (startDate, endDate) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/nias/job/get-calendar-events?startDate=${startDate}&endDate=${endDate}`
      );
      const data = await response.json();

      if (data.success) {
        const formattedEvents = formatJobsToEvents(data.results);
        setEvents(formattedEvents);
      } else {
        console.error("Failed to fetch jobs:", data.msg);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  const handleDatesSet = (info) => {
    const startDate = new Date(info.start).toISOString(); // Always in UTC
    const endDate = new Date(info.end).toISOString();

    fetchJobs(startDate, endDate);
  };

  const handleViewChange = (viewInfo) => {
    setCurrentView(viewInfo.view.type); // Update the current view type
  };

  const handleListView = () => {
    setListViewActive(true);
  };

  const handleCalendarView = (viewInfo) => {
    setCurrentView(viewInfo.view.type); // Update the current view type
    setListViewActive(false); // Switch back to the calendar
  };

  return (
    <div className="p-5">
       {listViewActive ? (
        <ListView onBackToCalendar={() => setListViewActive(false)}/>
      ) : (
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        businessHours={false}
        headerToolbar={{
          start: "prev,next today",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay,listView", // Add custom "listView" button
        }}
        customButtons={{
          listView: {
            text: "List", // Text for the custom button
            click: handleListView, // Handle click for the custom button
          },
        }}
        eventDidMount={(info) => {
          info.el.style.setProperty("--fc-event-bg-color", "transparent"); // Remove background
          info.el.style.setProperty("--fc-event-border-color", "transparent"); // Remove border
        }}
        viewDidMount={(info) => handleViewChange(info)}
        events={events}
        datesSet={handleDatesSet}
        dayCellContent={(arg) => {
          const eventCount = events.filter(
            (event) =>
              event.start.split("T")[0] === arg.date.toISOString().split("T")[0]
          ).length;

          return (
            <div className="text-center">
              <div>{arg.date.getDate()}</div>
              {eventCount > 0 && (
                <div className="text-xs text-gray-500">{`(${eventCount} events)`}</div>
              )}
            </div>
          );
        }}
        eventContent={(arg) => {
          if (currentView === "dayGridMonth") {
            return (
              <div
                className="w-full h-full text-white text-sm font-medium p-1 rounded-md"
                style={{
                  backgroundColor:
                    arg.event.extendedProps.backgroundColor || "#000",
                  color: arg.event.extendedProps.textColor || "#fff",
                }}
              >
                <b>{arg.event.title}</b>
              </div>
            );
          } else if (currentView === "timeGridDay") {
            return (
              <div
                className="text-sm max-w-fit text-white bg-green-500 rounded-md p-2"
                style={{
                  backgroundColor:
                    arg.event.extendedProps.backgroundColor || "#000",
                }}
              >
                <b>{arg.event.title}</b>
              </div>
            );
          }
        }}
        dateClick={(info) => {
          const calendarApi = info.view.calendar;
          calendarApi.changeView("timeGridDay", info.dateStr); // Navigate to day view
        }}
        slotLabelFormat={[
          { hour: "2-digit", minute: "2-digit", hour12: false },
        ]}
        eventClick={(info) => {
          alert(`Event: ${info.event.title}`);
        }}
        allDaySlot={false}
        dayMaxEvents={2} // Disable "+x more" behavior
        height="700px"
      />
    )}
    </div>
  )
};

export default DispatchCalendar;
