import React from 'react';

import EventsJobList from '../Add Job/Events/EventsJobList';
import RenalJobList from '../Add Job/Renal/RenalJobList';
import RepatriationJobList from '../Add Job/Repatriation/RepatriationJobList';
import { Button, buttonVariants } from '../Global/UI/Button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Global/UI/tabs';
import JobTypeDialog from './Home/SelectJobTypeModal';
import JobList from './JobList';
import NiasJobList from './NiasJobs';

const DashboardMain = () => {

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(() => {
    const storedTab = sessionStorage.getItem('activeTab');
    return storedTab ? storedTab : 'journeys';
  });

  React.useEffect(() => {
    sessionStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="w-full max-w-full">
    <JobTypeDialog isOpen={isDialogOpen} onClose={handleCloseDialog} />
    <div className="w-full flex flex-col mt-10">
      <div className="w-full flex flex-row justify-between items-center">
        <p className="font-semibold text-2xl">{`${activeTab !== 'nias' ? 'Journey Requests' : 'NIAS Requests'}`}</p>
        <Button
          onClick={() => setIsDialogOpen(true)}
          variant="default"
          size="default"
          className={buttonVariants({ variant: "default", size: "default" })}
        >
          Add a job
        </Button>
      </div>
      <div className="w-full">
        <Tabs defaultValue={activeTab} onValueChange={setActiveTab}>
          <TabsList className="mb-4">
            <TabsTrigger className="font-semibold" value="journeys">
              Journeys
            </TabsTrigger>
            <TabsTrigger className="font-semibold" value="nias">
              NIAS
            </TabsTrigger>
            <TabsTrigger className="font-semibold" value="repatriation">
              Repatriation
            </TabsTrigger>
            <TabsTrigger className="font-semibold" value="NIASRenal">
              NIAS Renal
            </TabsTrigger>
            <TabsTrigger className="font-semibold" value="Events">
              Events
            </TabsTrigger>
          </TabsList>
  
          <TabsContent value="journeys">
            <JobList />
          </TabsContent>
          <TabsContent value="nias">
            <div className="w-full max-w-full">
              <NiasJobList />
            </div>
          </TabsContent>
          <TabsContent value="repatriation">
            <RepatriationJobList />
          </TabsContent>
          <TabsContent value="NIASRenal">
            <RenalJobList />
          </TabsContent>
          <TabsContent value="Events">
            <EventsJobList />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  </div>
  );
}

export default DashboardMain;
