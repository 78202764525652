import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import DashboardMain from '../Components/Dashboard/DashboardMain';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../Components/Global/UI/Card';
import { BASE_URL } from './Settings/DASHBOARD_SETTINGS';

const Dashboard = () => {

  const [stats , setStats] = useState([])

  React.useEffect(() => {
    const getStats = async () => {
      try {
          const response = await axios.get(`${BASE_URL}/api/stats`);
          if (response.data.success) {
            console.log('Stats',response.data.results)
            setStats(response.data.results)
          }
      } catch (error) {
          if (error.response) {
              if (error.response.status === 400) {
                  toast.error(error.response.data.message);
                  console.error('Validation errors:', error.response.data.errors);
              }
          } else if (error.request) {
              toast.error("An unknown error has occured");
              console.error('No response received:', error.request);
          } else {
              toast.error("An unknown error has occured");
              console.error('Error setting up the request:', error.message);
          }
      }
  };
  getStats()
  },[])

  const statsData = [
    {
      CardTitle: "Journeys Today",
      CardDescription: "Total number of ambulance journeys scheduled for today",
      CardContent: `+${stats.journeysToday ? stats.journeysToday : '0'}`,
    },
    {
      CardTitle: "Shifts Today",
      CardDescription: "Total number of shifts scheduled for today",
      CardContent: `+${stats.shiftsToday ? stats.shiftsToday : '0'}`,
    },
    {
      CardTitle: "Shifts Tomorrow",
      CardDescription: "Total number of shifts scheduled for tomorrow",
      CardContent: `+${stats.shiftsTomorrow ? stats.shiftsTomorrow : '0'}`,
    },
    {
      CardTitle: "Jobs Today",
      CardDescription: "Total number of jobs across all categories scheduled for today",
      CardContent: `+${stats.jobsToday ? stats.jobsToday : '0'}`,
    },
  ];

  return (
    <div className="mx-10 mt-6 relative">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {statsData.map((data, index) => (
          <Card key={index}>
            <CardHeader>
              <CardTitle className="text-lg font-medium">{data.CardTitle}</CardTitle>
              <CardDescription>{data.CardDescription}</CardDescription>
            </CardHeader>
            <CardContent>
            <CardTitle>{data.CardContent}</CardTitle>
            </CardContent>
          </Card>
        ))}
      </div>
      <DashboardMain />
    </div>
  );
};

export default Dashboard;
